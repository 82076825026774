<template>
  <div class="gra">
    <el-timeline>
      <el-timeline-item timestamp="2/12" placement="top" >
        <el-card class="item">
          <h4>一轮复习-数学-英语</h4>
          <el-row class="time">
            <p>2月-6月</p>
          </el-row>
        </el-card>
      </el-timeline-item>
      <el-timeline-item timestamp="7/1" placement="top">
        <el-card class="item">
          <h4>二轮复习-数学-英语-专业课</h4>
          <el-row class="time">
            <p>7月-8月</p>
          </el-row>
        </el-card>
      </el-timeline-item>
      <el-timeline-item timestamp="9/1" placement="top">
        <el-card class="item">
          <h4>三轮复习-数学-英语-专业课-政治</h4>
          <el-row class="time">
            <p>9月-10月</p>
          </el-row>
        </el-card>
      </el-timeline-item>

      <el-timeline-item timestamp="10/10" placement="top">
        <el-card class="item">
          <h4>报考专业</h4>
          <el-row class="time">
            <p>9月-10月</p>
            <p>网上报名时间为2020年10月10日至10月31日，每天9:00——22:00。</p>
            <p>https://yz.chsi.com.cn</p>
            <p></p>
          </el-row>
        </el-card>
      </el-timeline-item>
      <el-timeline-item timestamp="11/1" placement="top">
        <el-card class="item">
          <h4>冲刺</h4>
          <el-row class="time">
            <p>11月-12月</p>
          </el-row>
        </el-card>
      </el-timeline-item>
      <el-timeline-item timestamp="12/20" placement="top">
        <el-card class="item">
          <h4>考试</h4>
          <el-row class="time">
            <p>12月中旬</p>
            <p>准考证、身份证、马克笔、铅笔、小刀、固体胶、水、手表、酒店</p>
          </el-row>
        </el-card>
      </el-timeline-item>
    </el-timeline>
  </div>
</template>

<script>
export default {
  name: "Graduate",
}
</script>

<style scoped >
.gra {
  width: 50%;
  height: 100%;
  margin: auto;
  text-align: left;
}
.item .time{
  margin-top: 0.625rem;
}
.pro {
  width: 100%;
  height: 6.25rem;
}
</style>
